import axios from 'axios'
function defaultEmailValidationData() {
    return {
        emailsValidationError: false,
        validatedEmails: [],
        invalidEmailsList: [],
        emailValidatedWithPublicAPI: false,
        validatingEmails: false,
        emailValidationOutcomes: {
            HIGH_RISK_EMAIL: 'high',
            LOW_RISK_EMAIL: 'low',
            UNKNOWN_RISK_EMAIL: 'unknown',
            UNVALIDATED_EMAIL: 'N/A'
        },
        backendError: false,
        foundInvalidEmails: false,
        emailValidationErrorTypes: {
            EMAIL_VALIDATION_BACKEND_ERROR: 'backendError',
            INVALID_EMAILS_ERROR: 'invalidEmails'
        }
    }
}
export const emailValidation = {
    data() {
        return defaultEmailValidationData()
    },
    methods: {
        setEmailValidationDefaultData: function() {
            Object.assign(this.$data, defaultEmailValidationData())
        },
        validateEmails: function(emailArray) {
            this.setEmailValidationDefaultData()
            this.$data.validatingEmails = true
            this.$store.dispatch('showSnackBar', {
                snackBarText: 'Validating emails, please wait....',
                snackBarTimeout: 1500,
                snackBarIcon: 'info'
            })
            const postBody = { email_list: emailArray }
            return this.$axios
                .post('/validate_email_address', postBody, { timeout: 300000 })
                .then(response => {
                    if (response.data) {
                        const emailList = []
                        response.data.forEach(email => {
                            if (email.risk === this.$data.emailValidationOutcomes.UNVALIDATED_EMAIL) {
                                this.$data.backendError = true
                            }
                            if (email.risk === this.$data.emailValidationOutcomes.HIGH_RISK_EMAIL) {
                                this.$data.invalidEmailsList.push({ email: email.address, status: email.risk })
                                this.$data.foundInvalidEmails = true
                            }
                            emailList.push({ email: email.address, status: email.risk })
                        })
                        this.$data.validatedEmails = emailList
                    }
                })
                .catch(() => {
                    this.$data.emailsValidationError = true
                })
                .finally(() => {
                    this.$data.validatingEmails = false
                })
        },
        validateEmailsWithPublicMailgunApi: function(email) {
            this.setEmailValidationDefaultData()
            this.$data.validatingEmails = true
            return axios
                .get('https://api.mailgun.net/v3/address/validate', {
                    params: {
                        address: email,
                        api_key: 'pubkey-290426e66acbb1da0fc03a7bf19866cb'
                    }
                })
                .then(response => {
                    if (response.data && response.data.is_valid === false) {
                        this.$data.foundInvalidEmails = true
                        this.$data.invalidEmailsList.push({ email: email })
                    } else if (response.data && response.data.is_valid === true) {
                        this.$data.emailValidatedWithPublicAPI = true
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.$data.emailsValidationError = true
                })
                .finally(() => {
                    this.$data.validatingEmails = false
                })
        }
    }
}
