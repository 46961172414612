var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-space-between align-center info-tile pa-2"
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "subtitle-2 mr-3 text-uppercase font-weight-bold"
                        },
                        [_vm._v("invite to: ")]
                      ),
                      _c("v-select", {
                        attrs: {
                          loading: _vm.fetchingInstances === true,
                          disabled: _vm.fetchingInstances === true,
                          items: _vm.instanceNames,
                          label: "Target instance",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.selectedInstance,
                          callback: function($$v) {
                            _vm.selectedInstance = $$v
                          },
                          expression: "selectedInstance"
                        }
                      })
                    ],
                    1
                  ),
                  _c("AddInstanceDialog", {
                    on: {
                      inviteUsers: function($event) {
                        return _vm.setInstanceNameToTheNewInstance(
                          $event.instanceName
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _vm.selectedInstance
                ? _c(
                    "div",
                    [
                      _c(
                        "v-radio-group",
                        {
                          attrs: { disabled: _vm.numInvitationsToSend !== 0 },
                          model: {
                            value: _vm.invitationType,
                            callback: function($$v) {
                              _vm.invitationType = $$v
                            },
                            expression: "invitationType"
                          }
                        },
                        _vm._l(_vm.invitationTypes, function(mode) {
                          return _c(
                            "div",
                            { key: mode.type },
                            [
                              _c("v-radio", {
                                staticClass: "mt-3 caption",
                                attrs: {
                                  color: "secondary",
                                  value: mode.value,
                                  label: mode.type
                                }
                              }),
                              _c(
                                "span",
                                {
                                  class: [
                                    _vm.invitationType === mode.value
                                      ? "caption secondary--text"
                                      : "caption"
                                  ]
                                },
                                [_vm._v(_vm._s(mode.description))]
                              )
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _vm.invitationType
                        ? _c(
                            "v-form",
                            {
                              ref: "form",
                              staticClass: "mt-3",
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            [
                              _c("h4", { staticClass: "primary--text" }, [
                                _vm._v("Email addresses")
                              ]),
                              _c("v-textarea", {
                                attrs: {
                                  required: "",
                                  rows: "3",
                                  "auto-grow": "",
                                  disabled: _vm.numInvitationsToSend !== 0
                                },
                                on: { input: _vm.updateEmailString },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [_vm._v("Enter user emails")]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  4181440253
                                ),
                                model: {
                                  value: _vm.emailsInput,
                                  callback: function($$v) {
                                    _vm.emailsInput = $$v
                                  },
                                  expression: "emailsInput"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.emailsInput.length
                        ? _c("div", { staticClass: "mt-3" }, [
                            _c("h3", { staticClass: "primary--text" }, [
                              _vm._v(
                                " The following users will receive an invitation email to become "
                              ),
                              _vm.invitationType === "instanceEditors"
                                ? _c("span", [_vm._v("editors")])
                                : _vm._e(),
                              _vm.invitationType === "instanceViewers"
                                ? _c("span", [_vm._v("viewers")])
                                : _vm._e(),
                              _vm._v(
                                ' of "' + _vm._s(_vm.selectedInstance) + '" '
                              )
                            ]),
                            _c(
                              "div",
                              _vm._l(_vm.emails, function(email, index) {
                                return _c(
                                  "ul",
                                  { key: index, staticClass: "mt-3" },
                                  [
                                    email.status ===
                                      _vm.emailValidationOutcomes
                                        .LOW_RISK_EMAIL ||
                                    email.status ===
                                      _vm.emailValidationOutcomes
                                        .UNKNOWN_RISK_EMAIL
                                      ? _c(
                                          "li",
                                          [
                                            _vm._v(
                                              " " + _vm._s(email.email) + " "
                                            ),
                                            _c(
                                              "v-chip",
                                              {
                                                staticClass: "ml-1",
                                                attrs: {
                                                  "x-small": "",
                                                  color: "success"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-1",
                                                    attrs: { small: "" }
                                                  },
                                                  [_vm._v("check_circle")]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-uppercase font-weight-bold"
                                                  },
                                                  [_vm._v("validated")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : email.status ===
                                        _vm.emailValidationOutcomes
                                          .HIGH_RISK_EMAIL
                                      ? _c(
                                          "li",
                                          [
                                            _vm._v(
                                              " " + _vm._s(email.email) + " "
                                            ),
                                            _c(
                                              "v-chip",
                                              {
                                                staticClass: "ml-1",
                                                attrs: {
                                                  "x-small": "",
                                                  color: "error"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-1",
                                                    attrs: { small: "" }
                                                  },
                                                  [_vm._v("cancel")]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-uppercase font-weight-bold"
                                                  },
                                                  [_vm._v("validation failed")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : email.status ===
                                        _vm.emailValidationOutcomes
                                          .UNVALIDATED_EMAIL
                                      ? _c(
                                          "li",
                                          [
                                            _vm._v(
                                              " " + _vm._s(email.email) + " "
                                            ),
                                            _c(
                                              "v-chip",
                                              {
                                                staticClass: "ml-1",
                                                attrs: {
                                                  "x-small": "",
                                                  color: "primary"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-1",
                                                    attrs: { small: "" }
                                                  },
                                                  [_vm._v("warning")]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-uppercase font-weight-bold"
                                                  },
                                                  [_vm._v("validation error")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm.validatingEmails === true
                                      ? _c(
                                          "li",
                                          [
                                            _vm._v(" " + _vm._s(email.email)),
                                            _c("v-progress-circular", {
                                              staticClass: "ml-1",
                                              attrs: {
                                                size: 20,
                                                indeterminate: "",
                                                color: "secondary"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _c("li", [_vm._v(_vm._s(email.email))])
                                  ]
                                )
                              }),
                              0
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c("InvitationErrorsDialog", {
                attrs: {
                  showDialog: _vm.showInvitationErrorsDialog,
                  errorType: _vm.validationErrorType,
                  invalidEmails: _vm.invalidEmailsList
                },
                on: {
                  proceedWithTheInvitation: function($event) {
                    return _vm.inviteUsers($event.value)
                  },
                  validateEmailsAgain: function($event) {
                    return _vm.validateEmailsAndInviteUsers($event.value)
                  }
                }
              })
            ],
            1
          ),
          _c("v-card-actions", [
            _c(
              "div",
              {
                staticClass: "d-flex align-center justify-center flex-column",
                staticStyle: { width: "100%" }
              },
              [
                _vm.$store.state.userInfo.is_sysadmin === true
                  ? _c("v-checkbox", {
                      staticClass: "mb-1 mt-5 pa-0",
                      attrs: {
                        label: "Generate invitations but don't send emails"
                      },
                      model: {
                        value: _vm.generateInvitationLinksOnly,
                        callback: function($$v) {
                          _vm.generateInvitationLinksOnly = $$v
                        },
                        expression: "generateInvitationLinksOnly"
                      }
                    })
                  : _vm._e(),
                _c(
                  "v-btn",
                  {
                    staticClass: "text-md-center mr-1",
                    attrs: {
                      loading:
                        _vm.numInvitationsToSend > 0 ||
                        _vm.inviting ||
                        _vm.validatingEmails,
                      disabled:
                        !_vm.valid ||
                        !_vm.emailsInput ||
                        _vm.numInvitationsToSend > 0 ||
                        _vm.validatingEmails,
                      color: "secondary"
                    },
                    on: {
                      click: function($event) {
                        return _vm.validateEmailsAndInviteUsers(true)
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [_vm._v("add")]),
                    _vm._v("Invite ")
                  ],
                  1
                ),
                _vm.error
                  ? _c(
                      "v-alert",
                      {
                        staticClass: "mt-4",
                        staticStyle: { width: "100%" },
                        attrs: { color: "error", icon: "warning", text: "" }
                      },
                      [
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v("Invitation Failure Error")
                          ]),
                          _vm._v(" Failed to invite the following users "),
                          _c(
                            "ul",
                            _vm._l(_vm.failedInvitations, function(
                              email,
                              index
                            ) {
                              return _c("li", { key: index }, [
                                _vm._v(" " + _vm._s(email) + " ")
                              ])
                            }),
                            0
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }