<template>
    <div>
        <v-card flat>
            <v-card-text>
                <div class="d-flex justify-space-between align-center info-tile pa-2">
                    <div class="d-flex align-center">
                        <span class="subtitle-2 mr-3 text-uppercase font-weight-bold">invite to: </span>
                        <v-select
                            :loading="fetchingInstances === true"
                            :disabled="fetchingInstances === true"
                            :items="instanceNames"
                            label="Target instance"
                            v-model="selectedInstance"
                            hide-details
                        ></v-select>
                    </div>
                    <AddInstanceDialog @inviteUsers="setInstanceNameToTheNewInstance($event.instanceName)"> </AddInstanceDialog>
                </div>
                <div v-if="selectedInstance">
                    <v-radio-group :disabled="numInvitationsToSend !== 0" v-model="invitationType">
                        <div v-for="mode in invitationTypes" :key="mode.type">
                            <v-radio class="mt-3 caption" color="secondary" :value="mode.value" :label="mode.type"></v-radio>
                            <span :class="[invitationType === mode.value ? 'caption secondary--text' : 'caption']">{{ mode.description }}</span>
                        </div>
                    </v-radio-group>
                    <v-form v-if="invitationType" class="mt-3" ref="form" v-model="valid">
                        <h4 class="primary--text">Email addresses</h4>
                        <v-textarea v-model="emailsInput" required @input="updateEmailString" rows="3" auto-grow :disabled="numInvitationsToSend !== 0">
                            <template v-slot:label>Enter user emails</template>
                        </v-textarea>
                    </v-form>
                    <div v-if="emailsInput.length" class="mt-3">
                        <h3 class="primary--text">
                            The following users will receive an invitation email to become
                            <span v-if="invitationType === 'instanceEditors'">editors</span>
                            <span v-if="invitationType === 'instanceViewers'">viewers</span>
                            of "{{ selectedInstance }}"
                        </h3>
                        <div>
                            <ul v-for="(email, index) in emails" :key="index" class="mt-3">
                                <li
                                    v-if="
                                        email.status === emailValidationOutcomes.LOW_RISK_EMAIL || email.status === emailValidationOutcomes.UNKNOWN_RISK_EMAIL
                                    "
                                >
                                    {{ email.email }}
                                    <v-chip class="ml-1" x-small color="success"
                                        ><v-icon small class="mr-1">check_circle</v-icon><span class="text-uppercase font-weight-bold">validated</span></v-chip
                                    >
                                </li>
                                <li v-else-if="email.status === emailValidationOutcomes.HIGH_RISK_EMAIL">
                                    {{ email.email }}
                                    <v-chip class="ml-1" x-small color="error"
                                        ><v-icon small class="mr-1">cancel</v-icon
                                        ><span class="text-uppercase font-weight-bold">validation failed</span></v-chip
                                    >
                                </li>
                                <li v-else-if="email.status === emailValidationOutcomes.UNVALIDATED_EMAIL">
                                    {{ email.email }}
                                    <v-chip class="ml-1" x-small color="primary"
                                        ><v-icon small class="mr-1">warning</v-icon
                                        ><span class="text-uppercase font-weight-bold">validation error</span></v-chip
                                    >
                                </li>
                                <li v-else-if="validatingEmails === true">
                                    {{ email.email }}<v-progress-circular :size="20" class="ml-1" indeterminate color="secondary" />
                                </li>
                                <li v-else>{{ email.email }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <InvitationErrorsDialog
                    :showDialog="showInvitationErrorsDialog"
                    :errorType="validationErrorType"
                    :invalidEmails="invalidEmailsList"
                    @proceedWithTheInvitation="inviteUsers($event.value)"
                    @validateEmailsAgain="validateEmailsAndInviteUsers($event.value)"
                />
            </v-card-text>
            <v-card-actions>
                <div class="d-flex align-center justify-center flex-column" style="width:100%">
                    <v-checkbox
                        v-if="$store.state.userInfo.is_sysadmin === true"
                        v-model="generateInvitationLinksOnly"
                        class="mb-1 mt-5 pa-0"
                        label="Generate invitations but don't send emails"
                    ></v-checkbox>
                    <v-btn
                        :loading="numInvitationsToSend > 0 || inviting || validatingEmails"
                        class="text-md-center mr-1"
                        @click="validateEmailsAndInviteUsers(true)"
                        :disabled="!valid || !emailsInput || numInvitationsToSend > 0 || validatingEmails"
                        color="secondary"
                    >
                        <v-icon small>add</v-icon>Invite
                    </v-btn>
                    <v-alert v-if="error" color="error" icon="warning" class="mt-4" text style="width:100%">
                        <div class="d-flex flex-column">
                            <span class="font-weight-bold">Invitation Failure Error</span>
                            Failed to invite the following users
                            <ul>
                                <li v-for="(email, index) in failedInvitations" :key="index">
                                    {{ email }}
                                </li>
                            </ul>
                        </div>
                    </v-alert>
                </div>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { splitEmails, sortArray } from '@/utils'
import { emailValidation } from '@/mixins/validateEmail'
import { mapState, mapGetters } from 'vuex'
import { enumsData } from '@/mixins/enums'
const AddInstanceDialog = () => import('@/modules/space/components/TheSpaceAddInstanceDialog')
const InvitationErrorsDialog = () => import('@/components/InvitationErrorsDialog')

export default {
    props: {
        dataForInstanceToInviteTo: { type: Object, default: null }
    },
    components: { AddInstanceDialog, InvitationErrorsDialog },
    mixins: [emailValidation, enumsData],
    data() {
        return {
            error: false,
            emailsInput: '',
            invitationType: 'instanceEditors',
            errorContent: 'An error has occurred',
            emailsData: [],
            emailList: [],
            failedInvitations: [],
            inviting: false,
            valid: false,
            numInvitationsToSend: 0,
            instanceMenu: false,
            selectedInstance: '',
            instanceSearch: '',
            tabValues: {
                INSTANCE_EDITOR_TAB: 'instanceEditors',
                INSTANCE_VIEWER_TAB: 'instanceViewers'
            },
            generateInvitationLinksOnly: false,
            invitationTypes: [
                {
                    type: 'Editors',
                    description:
                        'The invitee will be able to modify the contents of the specified instance and start applications there, but will not be able to invite other people or create new instances.',
                    value: 'instanceEditors'
                },
                {
                    type: 'Viewers',
                    description:
                        'The invitee will be able to read or distribute the contents of the instance to other spaces, but will not be able to start an application or invite people/create new instances.',
                    value: 'instanceViewers'
                }
            ]
        }
    },
    computed: {
        ...mapState('spaceStore', ['spaceInstances', 'spaceUsers', 'fetchingInstances']),
        ...mapGetters('spaceStore', ['currentSpaceType']),
        instanceNames() {
            var instanceNameList = []
            if (this.spaceInstances && this.currentSpaceType === this.$data.spaceTypes.RESEARCH_SPACE) {
                const filteredInstances = this.spaceInstances.filter(instance => instance.long_id !== this.$data.instanceFixedNames.DISTRIBUTED_INSTANCE)
                const sortedInstances = sortArray(filteredInstances, 'long_id', 'ascending', true)
                instanceNameList = sortedInstances.map(instance => instance.long_id)
            } else if (this.spaceInstances) {
                const sortedInstances = sortArray(this.spaceInstances, 'long_id', 'ascending', true)
                instanceNameList = sortedInstances.map(instance => instance.long_id)
            }
            return instanceNameList
        },
        validationErrorType() {
            if (this.$data.foundInvalidEmails === true) {
                return this.$data.emailValidationErrorTypes.INVALID_EMAILS_ERROR
            } else if (this.$data.backendError === true || this.$data.emailsValidationError === true) {
                return this.$data.emailValidationErrorTypes.EMAIL_VALIDATION_BACKEND_ERROR
            }
            return null
        },
        selectedInstanceId() {
            if (this.$data.selectedInstance && this.spaceInstances) {
                const selectedInstance = this.spaceInstances.filter(instance => instance.long_id === this.$data.selectedInstance)
                return selectedInstance[0].iid
            }
            return null
        },
        showInvitationErrorsDialog() {
            if (this.$data.foundInvalidEmails === true || this.$data.backendError === true || this.$data.emailsValidationError === true) {
                return true
            }
            return false
        },
        emails: function() {
            if (this.$data.validatedEmails.length) {
                return this.$data.validatedEmails
            } else if (this.$data.emailsData.length) {
                return this.$data.emailsData
            }
            return []
        }
    },
    mounted() {
        if (this.$props.dataForInstanceToInviteTo) {
            this.$data.selectedInstance = this.$props.dataForInstanceToInviteTo.instanceName
        } else {
            this.$data.selectedInstance = this.$data.instanceFixedNames.MASTER_INSTANCE
        }
    },
    watch: {
        dataForInstanceToInviteTo: function(nextVal, preVal) {
            if (nextVal && nextVal.instanceName) {
                this.$data.selectedInstance = nextVal.instanceName
            }
        }
    },
    methods: {
        updateEmailString: function() {
            this.$data.validatedEmails = []
            const emails = splitEmails(this.$data.emailsInput)
            this.$data.emailsData = emails
            this.$data.emailList = emails.map(email => email.email)
        },
        setInstanceNameToTheNewInstance: function(instanceName) {
            this.$data.selectedInstance = instanceName
        },
        inviteUsers: function(confirmed) {
            if (confirmed === true) {
                this.$data.error = false
                var apiURL
                if (this.$data.invitationType === this.$data.tabValues.INSTANCE_EDITOR_TAB) {
                    apiURL = '/invitations/invite_instance_editor/' + this.selectedInstanceId
                } else if (this.$data.invitationType === this.$data.tabValues.INSTANCE_VIEWER_TAB) {
                    apiURL = '/invitations/invite_instance_viewer/' + this.selectedInstanceId
                }
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Inviting users...',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'info'
                })
                this.$data.inviting = true
                this.$data.numInvitationsToSend = this.$data.emailList.length
                this.$data.emailList.forEach(email => {
                    const postBody = { requestee_email: email, generate_link_only: this.$data.generateInvitationLinksOnly }
                    this.$axios
                        .post(apiURL, postBody, { timeout: 300000 })
                        .then(response => {})
                        .catch(() => {
                            this.$data.failedInvitations.push(email)
                            this.$data.error = true
                        })
                        .finally(() => {
                            this.$data.numInvitationsToSend -= 1
                            if (this.$data.numInvitationsToSend === 0) {
                                this.$data.inviting = false
                                this.$data.emailsInput = ''
                                this.$store.dispatch('spaceStore/fetchSpaceInvitations', this.$route.params.sid)
                                this.$store.dispatch('showSnackBar', {
                                    snackBarText: 'Invitations have been successfully sent. Invited users will receive an email to accept the invitation.',
                                    snackBarTimeout: 5000,
                                    snackBarIcon: 'check_circle'
                                })
                            }
                        })
                })
            }
        },
        validateEmailsAndInviteUsers: function(confirmed) {
            if (confirmed === true) {
                this.validateEmails(this.$data.emailList).finally(() => {
                    if (this.$data.emailsValidationError === false && this.$data.backendError === false && this.$data.foundInvalidEmails === false) {
                        this.inviteUsers(true)
                    }
                })
            }
        }
    }
}
</script>
